.countdown-container {
  margin-left: 1rem;
}

.countdown-container h4 {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #30b587;
  margin-bottom: 0;
}

@media only screen and (max-width: 480px) {

}
