.gallery-section {
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header {
  text-align: center;
  margin-bottom:3.5rem;
}

.header h2 {
    margin-bottom:1rem;
}

.gallery-heading-text {
  margin-bottom:1.5rem;
}

.gallery-section {
    padding-top:48px;
}