@import "../../Variables.css";

.intership-section {
  background: #f2f4f6;
  padding: 5rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 6px;
  width: 100%;
}

.intership-section h4 {
  color: var(--green-primary);
}

.internship-subTitle {
  margin-bottom: 0px;
}

.internship-main-grid {
  display: grid;
  grid-template-areas:
    "one two two three four"
    "one five six six four";
  height:380px;
  max-width: 1120px;
  grid-auto-columns: 1fr;
  gap: 1rem;
  margin: 3.5rem 0px;
}

.grid-item {
  min-width: 0;
  min-height: 0;
  overflow:hidden;
  height:100%;
  width:100%; 
}

.internship-picture {
  object-fit: cover;
  border-radius: 6px;
  width:100%;
  height:100%;
}

.grid-item:nth-child(1) {
  grid-area: one;
}

.grid-item:nth-child(2) {
  grid-area: two;
}

.grid-item:nth-child(3) {
  grid-area: five;
}

.grid-item:nth-child(4) {
  grid-area: three;
}

.grid-item:nth-child(5) {
  grid-area: six;
}

.grid-item:nth-child(6) {
  grid-area: four;
}

.internship-participants {
  max-width: 600px;
}

@media only screen and (max-width: 950px) {
  .internship-main-grid {
    grid-template-areas:
      "one two"
      "one six"
      "four three"
      "four five";
    height: 900px;
  }

  .internship-picture:nth-child(3) {
    object-position: 0px -130px;
  }
}

@media only screen and (max-width: 810px) {
  .internship-picture:nth-child(3) {
    object-position: 0px -100px;
  }
}

@media only screen and (max-width: 650px) {
  .internship-main-grid {
    max-height: 3077px;
    max-width: 375px;
    grid-template-areas:
      "two two"
      "five one"
      "four one"
      "four three"
      "six six";
  }

  .internship-picture:nth-child(3) {
    object-position: 0px 0px;
  }

  .internship-picture:nth-child(5) {
    max-height: 250px;
  }
}
