@import "../../Variables.css";

.rewards-section {
  display: flex;
  flex-direction: column;
}

.prizes-container {
  padding: 2rem 4rem;
  margin-top: 7rem;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
}

.rewards-section li {
  font-weight:500;
}

.image-trophies {
  max-width:352px;
  border-radius:6px;
}

.third-place {
  position: relative;
}

.second-place {
  position: relative;
}

.first-place {
  margin-top: -100px;
  position: relative;
}

.second-place-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.second-place-memberList {
  list-style: none;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.team-titles {
  color: var(--green-primary);
  margin-top: 1rem;
  margin-bottom: 8px;
}

.trophies {
  position: absolute;
  width: 95.6px;
  height: 90.99px;
  left: calc(50% - 89.6px / 2 - 0.92px);
  top:-50px;
  padding:15px;
  z-index: 3;
}

.white-background-circle {
    position:absolute;
    background: white;
    left: calc(50% - 90.6px / 2 - 0.92px);
    top:-50px;
    width: 95.6px;
    height: 95.6px;
    border-radius:50%;
}


@media only screen and (max-width: 1125px) {
    .prizes-container {
      padding: 2rem 4rem;
      gap:1rem;
    }

    .second-place-description h3 {
        padding:0px 5px;
    }

    .image-trophies {
        max-width: 100%;
    }

    .trophies {
        width:85px;
        height:80.99px;
    }

    .white-background-circle {
        width:85.6px;
        height:85.6px;
    }
  }

  @media only screen and (max-width: 800px) {
    .prizes-container {
        flex-direction: column;
        padding-top:0rem;
        gap:5rem;
    }

    .image-trophies {
      max-width:100%;
    }

    .trophies {
      left: calc(50% - 84.6px / 2 - 0.92px);
    }

    .white-background-circle {
      left: calc(50% - 85.6px / 2 - 0.92px);
    }

    .rewards-section {
        max-height: fit-content;
    }

    .first-place {
        margin-top: 0px;
    }

  }

  @media only screen and (max-width: 420px) {
    .prizes-container {
        padding: 0rem 1rem;
    }
  }