.main-grid {
  display: grid;
  grid-template-areas:
    "one two three "
    "one four three "
    "five four six";
  gap: 30px;
  margin-top: 2rem;
}

.grid-image {
  object-fit: cover;
  border-radius: 6px;
  height: 100%;
  width: 100%;
}

.grid-image:nth-child(1) {
  grid-area: one;
}

.grid-image:nth-child(2) {
  grid-area: two;
}

.grid-image:nth-child(3) {
  grid-area: three;
}

.grid-image:nth-child(4) {
  grid-area: four;
}

.grid-image:nth-child(5) {
  grid-area: five;
}

.grid-image:nth-child(6) {
  grid-area: six;
}


@media only screen and (max-width: 950px) {
  .main-grid {
    grid-template-areas:
      "one two"
      "one six"
      "four three"
      "four five";

  }
}

@media only screen and (max-width: 650px) {
  .main-grid {
    max-height: 3077px;
    max-width: 375px;
    grid-template-areas:
      "two two"
      "five one"
      "four one"
      "four three" 
      "six six";
  }
}
