@import '../../../Variables.css';

.testimonial-card-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.testimonial-card-content {
    max-width: 352px;
    height:128px;
    padding:24px;
    background-color:var(--color-light-gray);
    margin-top:2.5rem;
    border-radius:6px;
    font-weight:500;
}

.testimonial-card-memberName {
    color: var(--green-primary);
    margin-top: 1.5rem;
}