.footer {
  width: 100%;
  height: 84px;
  background-color: #262424;
  display: flex;
  justify-content: center;
  align-items: center;
}

.copyright-container {
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  color: #fff;
}
