.header {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  background: #fff;
  z-index: 5;
}

.header-container {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  padding-right: 4rem;
}

.navbar {
  padding: 0;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.header-container a {
  text-decoration: none;
  color: #000;
  font-weight: 700;
  font-size: 18px;
}

.apply-button,
.apply-button-mobile {
  padding: 13px 52px;
  background: #30b587;
  border-radius: 6px;
  border: none;
  font-weight: 700;
  font-size: 18px;
  color: #fff;
  margin-left: 1rem;
  transition: 0.5s;
}

.apply-button:hover {
  background: #27916c;
}

.mobile-nav {
  display: none;
}

.navbar-collapse {
  display: none;
  position: absolute;
  top: 75px;
  width: 100%;
  left: 0px;
  text-align: left;
}

.navbar-collapse a {
  padding: 1rem;
}

.apply-container {
  display: flex;
}

.apply-container a {
  padding: 13px 52px;
}

.apply-container a:not([href]),
.apply-container:hover {
  color: #fff;
}

@media only screen and (max-width: 1090px) {
  .header-container {
    justify-content: space-between;
  }

  .nav-container {
    display: none;
  }

  .apply-button {
    display: none;
  }

  .apply-button-mobile {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .mobile-nav {
    display: block;
  }

  .navbar-collapse {
    display: block;
  }

  .logo {
    padding-right: 0;
  }
}
