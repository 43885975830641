.article-container {
  display: flex;
  justify-content: space-between;
}

.text-container {
  flex: 1;
  text-align: left;
  padding-right: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.text-container p {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin-top: 1rem;
}

.apply-button {
  margin-top: 1rem;
  padding: 13px 52px;
  background: #30b587;
  border-radius: 6px;
  border: none;
  font-weight: 700;
  font-size: 18px;
  color: #fff;
  transition: 0.5s;
  cursor: pointer;
}

.apply-button:hover {
  background: #27916c;
}

.image-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 2rem;
}

.image-container img {
  object-fit: cover;
  border-radius: 6px;
}

.image-container h3 {
  color: #30b587;
  margin-bottom: 0;
  margin-left: 1rem;
}

.green-header {
  color: #30b587;
  font-weight: 700;
}

.countdown-container {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  background: #d3feef;
  border-radius: 6px;
  padding: 16px 32px;
}

.clock-container {
  display: flex;
  align-items: center;
}

.apply-container {
  display: flex;
}

.apply-container svg {
  margin-left: 0.5rem;
  margin-bottom: -2px;
}

.apply-container a:not([href]),
.apply-container:hover {
  color: #fff;
}

.countdown-closed {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 1150px) {
  .article-container {
    flex-direction: column;
  }

  .text-container {
    padding-right: 0;
    margin-bottom: 1rem;
  }

  .image-container {
    padding-left: 0;
  }
}

@media only screen and (max-width: 480px) {
  .countdown-container {
    justify-content: center;
  }

  .countdown-closed {
    flex-direction: column;
    width: auto;
    justify-content: end;
    align-items: start;
  }
}
