.contact-section {
  background: #f2f4f6;
  box-shadow: 0 0 0 100vmax #f2f4f6;
  clip-path: inset(0 -100vmax);
  padding: 80px 0px;
}

.contact-form {
  padding: 32px;
  max-width: 736px;
  min-height: 584px;
  margin: auto;
  margin-top: 56px;
  background: #fff;
  border-radius:6px;
}

.field-container {
  display: flex; 
  justify-content: space-between;
}

.field-container:nth-child(2) {
  margin-top:2rem;
  margin-bottom:2rem;
}

@media only screen and (max-width: 740px)  {
  .field-container {
    flex-direction: column;
    gap:1rem;
  }

  .field-container:nth-child(2) {
    margin-top:1rem;
    margin-bottom:1rem;
  }
}