@import '../../../Variables.css';

.button {
    border:none;
    padding:14px 42px;
    border-radius:6px;
    background-color: var(--green-primary);
    color:var(--color-white);
    font-weight:700;
    transition: background-color 0.2s ease-out;
}

.button:hover {
    background-color: var(--green-hover);
}

.button:focus {
    outline-color:var(--green-primary);
}