@import '../../../Variables.css';

.form-input {
  display: flex;
  flex-direction: column;
  position:relative;
}

.form-input-field {
  padding: 8px;
  width: 320px;
  max-height: 40px;
  border-radius: 6px;
  border: 1px solid #f2f4f6;
}

.form-input-label {
  align-self: flex-start;
}

.form-input span {
  color: red;
  font-size: 11px;
  align-self: flex-start;
  display:none;
  position:absolute;
  top:72px;
  text-align: left;
}

input:focus {
  border-color: var(--green-primary);
  outline:none;
  box-shadow: 0 0 2px var(--green-primary);
} 

input:invalid[focused="true"] ~ span {
  display:block;
}

input:invalid[focused="true"] {
    border-color: red;
}


