.partners-section {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f2f4f6;
  box-shadow: 0 0 0 100vmax #f2f4f6;
  clip-path: inset(0 -100vmax);
  padding-bottom: 5rem;
}


.contact-us {
  width:100%;
}

.header {
  min-width: 270px;
  text-align: center;
  margin: auto;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.header h2 {
  color: #262424;
}

.sliderimg {
  margin-top: 1rem;
}

.partners-main-grid {
  display: grid;
  grid-template-areas:
    "one two two three four"
    "one five six six four";
  height: 381px;
  max-width: 1120px;
  gap: 1rem;
  margin: 3.5rem 0px;
}

.redbull-image img {
  width: 150px;
  max-height: 90px;
}

.partners-picture {
  width:100%;
  height:100%;
  object-fit: cover;
  border-radius: 6px;
}

.partners-picture:nth-child(1) {
  grid-area: one;
}

.partners-picture:nth-child(2) {
  grid-area: two;
}

.partners-picture:nth-child(3) {
  grid-area: three;
}

.partners-picture:nth-child(4) {
  grid-area: five;
}

.partners-picture:nth-child(5) {
  grid-area: six;
}

.partners-picture:nth-child(6) {
  grid-area: four;
}

.partners-section button {
  display: inline-block;
  max-width: 156px;
  width: 100%;
  padding: 14px 0px;
  align-self: center;
}

.partners-section h4 {
  font-weight: 500;
  font-size: 18px;
  max-width: 511px;
  align-self: center;
  margin-bottom: 2rem;
}

@media only screen and (max-width: 950px) {
  .partners-main-grid {
    grid-template-areas:
      "one two"
      "one six"
      "four three"
      "four five";
    height: 900px;
  }
}

@media only screen and (max-width: 650px) {
  .partners-main-grid {
    max-height: 3077px;
    max-width: 375px;
    grid-template-areas:
      "one one"
      "four two"
      "six two"
      "six three"
      "five five";
  }

  .partners-picture:nth-child(1) {
    max-height: 250px;
  }

}
