@import '../../Variables.css';

.hackaton-section {
  width: 100%;
  background: #f2f4f6;
  margin: 2rem auto;
}

.hackathon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.description-text {
  max-width: 542px;
  margin: auto;
}

.header {
  margin-bottom: 2rem;
}

.header h2 {
  color: #262424;
}

.info-text {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.info-text div {
  flex: 1;
  text-align: left;
}

.question-container {
  padding: 1rem;
}

.question-container h3 {
  color: var(--green-primary);
}

.answer-container {
  display: flex;
  align-items: flex-start;
  padding: 0.5rem 0;
}
.answer-container img {
  padding-top: 8px;
}

.answer-text {
  margin-left: 10px;
}


.answer-container p {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #262424;
}

@media only screen and (max-width: 749px) {
  .info-text {
    flex-direction: column;
  }
}
