.form-textarea-field {
  min-width: 100%;
  min-height: 204px;
  padding: 8px;
  border-radius: 6px;
  border: 1px solid #f2f4f6;
  resize: none;
}

.form-textarea-label {
  align-self: flex-start;
}

.form-textarea {
  display: flex;
  flex-direction: column;
  position:relative;
}

.form-textarea-field:focus {
  border-color: var(--green-primary);
  outline:none;
  box-shadow: 0 0 2px var(--green-primary);
}


.form-textarea span {
  color: red;
  font-size: 13px;
  align-self: flex-start;
  display:none;
  position:absolute;
  top:240px;
}

textarea:invalid[focused="true"] ~ span {
  display:block;
}

textarea:invalid[focused="true"] {
    border-color: red;
}
