@import './Variables.css';

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hackaton2022-content {
  margin-top: 120px;
}

.section-container {
  width: 100%;
  max-width: 1440px;
  margin: 2rem auto;
  padding: 30px 160px;
}

.background-section-container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 62px 160px;
}

h1 {
  font-weight: 500;
  font-size: 54px;
  line-height: 55px;
}

h2 {
  font-weight: 700;
  font-size: 38px;
  line-height: 46px;
}

h3 {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
}

h4 {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
}

.nav-link {
  transition: all 0.3s ease;
}

.nav-link:hover {
  color:var(--green-primary) !important;
}

.heading-text p {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #555151;
}

.nav-link {
  cursor: pointer;
}

.form-control:focus,
.custom-file-input:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.custom-file-input:focus ~ .custom-file-label {
  border: 1px solid #555151;
  border-color: none;
  box-shadow: none;
}

.alice-carousel__dots-item:not(.__custom):hover,
.alice-carousel__dots-item:not(.__custom).__active {
  background-color: #28a745 !important;
}

@keyframes inAnimation {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes outAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@media only screen and (max-width: 1439px) {
  .section-container {
    padding: 20px 3%;
  }

  .background-section-container {
    padding: 42px 3%;
  }
}

@media only screen and (max-width: 749px) {
  .hackaton2022-content {
    margin-top: 100px;
  }

  .section-container {
    padding: 10px 20px;
  }

  .background-section-container {
    padding: 32px 20px;
  }

  h1 {
    font-weight: 500;
    font-size: 32px;
    line-height: 39px;
    color: #262424;
  }

  h2 {
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    color: #262424;
  }

  h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #262424;
  }

  h4 {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #262424;
  }
}
